import consumer from "./consumer"

$(function() {
  createChessCellSub();
});

window.createChessCellSub = function () {
  $('.chess--cell-cable').each(function() {
    consumer.subscriptions.create({ channel: "ChessCellsChannel", id: $(this).attr('data-realty-id'), namespace: $(this).attr('data-namespace')}, {
      connected() {
        console.log("Connected to the room!");
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        let searchParams = new URLSearchParams(window.location.search),
            cell = $('#realty_' + data.realty_id);

        if (searchParams.get('display') == 'small') {
          cell.replaceWith(data.small_cell);
        } else {
          cell.replaceWith(data.big_cell);
        }
      }
    });
  });
}
