$(document).ready(function () {
  var input_group = $('.sms-code-input-group');
  var session_code_input = $('#session_code');
  var session_form = document.getElementById('new-session-form');

  input_group.keyup(moveToNextInput);
  input_group.on('input', function () {
    var vals = input_group.find('input').map(function () {
      var value = $.trim(this.value);
      return value ? value : undefined;
    }).get();
    session_code_input.val(vals.join(''));
    session_code_input.trigger('change');
  });

  session_code_input.on('change', function () {
    var $this = $(this);
    if ($this.val().length === 4) {
      $.rails.fire(session_form, 'submit');
    }
  });
});

// Переход к следующему инпуту после достижения максимальной его длины
function moveToNextInput(e) {
  var target = e.srcElement || e.target;
  var maxLength = parseInt(target.attributes["maxlength"].value, 10);
  var myLength = target.value.length;
  if (myLength >= maxLength) {
    var next = target;
    while (next = next.nextElementSibling) {
      if (next == null)
        break;
      if (next.tagName.toLowerCase() === "input") {
        next.focus();
        break;
      }
    }
  } else if (myLength === 0) {
    var previous = target;
    while (previous = previous.previousElementSibling) {
      if (previous == null)
        break;
      if (previous.tagName.toLowerCase() === "input") {
        previous.focus();
        var val = previous.value;
        previous.value = '';
        previous.value = val;
        break;
      }
    }
  }
}