$(document).ready(function () {
  $(document).on('ajax:error', 'form[data-remote]', function(event) {
    const [data, status, xhr] = event.detail;
    if (xhr.status === 422) {
      var alert = document.createElement('div'),
          errorDiv = document.getElementById('errors');

      alert.className = 'alert alert-danger';
      alert.innerText = data;
      errorDiv.innerHTML = '';
      errorDiv.append(alert);
    }
  });
});
