// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("jquery-ui/ui/widgets/autocomplete");
require("bootstrap");
require("chartkick");
require("chart.js");
require("hc-sticky"); // TODO не используется, возможно надо будет вернуть, иначе удалить
require("simplebar");
require("lightbox2");
require("./datepicker.js");
require("./dadata.js");
require("./inputmask.js");
require("./nested_form");
require("./show_elements");
require("./sms_code_input");
require("./common");
require("./dragndrop");
require("./countdown");
require("./charts");
require("./invalid_remote_form");
require("./map.js");
require("./chess_order_form")

import $ from 'jquery'
window.jQuery = $;
window.$ = $;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

import "../stylesheets/all.sass"
