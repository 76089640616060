window.countdown = function (counter, callback) {
  var timer2 = counter.textContent;

  var timer = timer2.split(':');
  var minutes = parseInt(timer[0], 10);
  var seconds = parseInt(timer[1], 10);
  --seconds;
  minutes = (seconds < 0) ? --minutes : minutes;
  console.log(minutes)
  if (minutes < 0) clearInterval(interval);
  seconds = (seconds < 0) ? 59 : seconds;
  seconds = (seconds < 10) ? '0' + seconds : seconds;
  minutes = (minutes < 10) ? '0' + minutes : minutes;

  var value = (minutes + ':' + seconds);

  if (value == '00:00' && callback !== undefined) {
    callback();
  } else if (minutes >= 0) {
    counter.innerHTML = value;
  } else {
    return false;
  }
};