// Получение валидных данных для датапикера срока бронирования
window.getBookingPeriodRequest = function (realty_id, order_id) {
  var url = '/realties/' + realty_id + '/orders/get_booking_period/';
  var data = $("#new_order").find("[name!='_method']").serializeArray();

  if (order_id) {
    url += order_id
  }

  $.ajax({
    url: url,
    method: 'post',
    data: $.param(data),
    dataType: 'json',
    cache: false
  })
  .done(function(data) {
    initDatepicker({
      defaultDate: data['default_date'],
      enable: data['enable_dates']
    });
  });
};

$(document).ready(function () {
  $('#orderFormModal').on('shown.bs.modal', function (e) {
    initNestedForm();

    var orderForm = $('#new_order');

    orderForm.find('input[name="order_form[payment_type]"]').on('change', function () {
      $(this).closest('form').find('.approved-mortgage').toggleClass('d-none', $(this).val() != 'mortgage');
    });
	
	orderForm.find('select[name="order_form[booking_type]"]').on('change', function () {
	  $(this).closest('form').find('.fixed-charged-options').toggleClass('d-none', $(this).find('option').filter(':selected').val() != 'fixed_charged');
    });

    $('#display_input').val($('#display').val());

    orderForm.on('ajax:success', function () {
      $('#orderFormModal').modal('hide');
    });
  });
});
