import consumer from "./consumer"

$(function() {
  consumer.subscriptions.create({ channel: "OrdersChannel", id: $('.order').attr('data-order-id')}, {
    connected() {
      console.log("Connected to the room!");
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      $('#booking-section').html(data.html);
    }
  });
});
