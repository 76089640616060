$(function () {

  $('[data-toggle="tooltip"]').tooltip();
  $('.chess--popover-js').popover({
    html: true,
    template: '<div class="popover chess--popover" role="tooltip"><div class="arrow"></div><div class="popover-body chess--popover-body"></div></div>',
    offset: '60px 0'
  });

  $(document).on('change', '.autosubmit-form input, .autosubmit-form select, .autosubmit-form textarea', function() {
    var formId = $(this).closest('form').attr('id');
    var form = document.getElementById(formId);

    if (form.getAttribute('data-remote')) {
      $.rails.fire(form, 'submit');
    } else {
      form.submit();
    }
  });

  $(document).on('change', '.autosubmit-input--remote', function() {
    if ($(this).attr('form')) {
      var form = $('#' + $(this).attr('form'));
    } else {
      var form = $(this).closest('form');
    }

    form.attr('data-remote', true)
  });

  $(document).on('change', '.autosubmit-input', function() {
    if ($(this).attr('form')) {
      var formId = $(this).attr('form');
    } else {
      var formId = $(this).closest('form').attr('id');
    }

    var form = document.getElementById(formId);

    if (form.getAttribute('data-remote')) {
      $.rails.fire(form, 'submit');
    } else {
      form.submit();
    }
  });

  $(document).on('click', '.js-switch-chess-view-btn', function(){
    $('.js-switch-chess-view-input').val($(this).data('value')).trigger('change');
  });

  $('.custom-file-input').on('change', function() {
    var fileName = $(this).val();
    $(this).next('.custom-file-label').html(fileName);
  })

  $(document).on('ajax:beforeSend', '*[data-remote="true"]', function(event) {
    const [xhr, options] = event.detail;
    if (buildAuthorizationHeader()) {
      xhr.setRequestHeader('Authorization', buildAuthorizationHeader());
    }
  });

  $.ajaxSetup({
    beforeSend: function(xhr) {
      if (buildAuthorizationHeader()) {
        xhr.setRequestHeader('Authorization', buildAuthorizationHeader());
      }
    }
  });
});

function buildAuthorizationHeader() {
  var searchParams = new URLSearchParams(window.location.search);

  if (searchParams.has('token')) {
    var header = 'Bearer ' + searchParams.get('token');
  } else {
    return false;
  }

  if (searchParams.has('subdomain')) {
    header += ', subdomain=' + searchParams.get('subdomain');
  }

  if (searchParams.has('lead_id')) {
    header += ', lead_id=' + searchParams.get('lead_id');
  }

  return header;
}
