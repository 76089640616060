document.addEventListener('DOMContentLoaded', function () {

  const openRows = document.querySelectorAll('.house-row__open');
  openRows.forEach(item => {
    item.addEventListener('click', function (e) {
      e.preventDefault();
      this.closest('.house-row').style.display = 'none';
      this.closest('.house-row').nextElementSibling.style.display = 'block';
    });
  });

  const closeRows = document.querySelectorAll('.house-row-full__close');
  closeRows.forEach(item => {
    item.addEventListener('click', function (e) {
      e.preventDefault();
      this.closest('.house-row-full').style.display = 'none';
      this.closest('.house-row-full').previousElementSibling.style.display = 'block';
    });
  });

  const openHouses = document.querySelectorAll('.he-card__open');
  openHouses.forEach(item => {
    item.addEventListener('click', function (e) {
      e.preventDefault();
      this.closest('.he-card').querySelector('.he-card__houses').style.display = 'block';
      this.closest('.he-card').querySelector('.he-card__close').style.display = 'block';
      this.style.display = 'none';
    });
  });

  const closeHouses = document.querySelectorAll('.he-card__close');
  closeHouses.forEach(item => {
    item.addEventListener('click', function (e) {
      e.preventDefault();
      this.closest('.he-card').querySelector('.he-card__houses').style.display = 'none';
      this.closest('.he-card').querySelector('.he-card__open').style.display = 'block';
      this.style.display = 'none';
    });
  });

});