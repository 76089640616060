window.initDragndrop = function () {
  var dropArea = document.getElementsByClassName('drop-area');

  for (let i = 0; i <= dropArea.length; i++) {
    if (dropArea[i] !== undefined) {
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(function (elem) {
        dropArea[i].addEventListener(elem, preventDefaults);
      });
      ['dragenter', 'dragover'].forEach(function (elem) {
        dropArea[i].addEventListener(elem, highlight);
      });
      ['dragleave', 'drop'].forEach(function (elem) {
        dropArea[i].addEventListener(elem, unhighlight);
      });
      dropArea[i].addEventListener('drop', (event) => {
        handleDrop(event)
      });

      let fileInput = dropArea[i].querySelector('input.file');
      fileInput.addEventListener('change', function () {
        var fileName = this.files[0].name;
        dropArea[i].querySelector('.box__input span').innerHTML = fileName
      });

      if (dropArea[i].classList.contains('remote')) {
        let form = dropArea[i].querySelector('form');
        let fileInput = dropArea[i].querySelector('input.file');

        form.addEventListener('change', function () {
          let form = fileInput.closest('form');
          $.rails.fire(form, 'submit');
        });

        form.addEventListener('ajax:send', function () {
          dropArea[i].querySelector('.box__input span').innerHTML = 'Выполняется загрузка данных, пожалуйста подождите...';
        });
      }
    }
  }

}

function preventDefaults(e) {
  e.preventDefault();
  e.stopPropagation();
}

function highlight(e) {
  this.classList.add('highlight');
}

function unhighlight(e) {
  this.classList.remove('highlight');
}

function handleDrop(e) {
  let dt = e.dataTransfer;
  let files = dt.files;
  let fileInput = e.currentTarget.querySelector('input.file');

  fileInput.files = files;
  var fileName = files[0].name;
  e.currentTarget.querySelector('.box__input span').innerHTML = fileName

  if (e.currentTarget.classList.contains('remote')) {
    let form = fileInput.closest('form');
    $.rails.fire(form, 'submit');
  }
}

document.addEventListener('DOMContentLoaded', function () {
  initDragndrop();
});
