import 'inputmask/dist/jquery.inputmask.min'

$(document).ready(function () {
  $('input[autofocus]').trigger('focus');
});

$(document)
    .on("focus", ".phone_mask", function () {
      $(this).inputmask({
        mask: "+9 (999)-999-99-99",
        removeMaskOnSubmit: true
      });
    })
    .on("focus", ".department-code_mask", function () {
      $(this).inputmask("999-999");
    })
    .on("focus", ".cadastral_mask", function () {
      $(input).inputmask({
        mask: '9{2}:9{2}:9{1,7}:9{1,}'
      });
    })