$(function () {
  var chart = Chartkick.charts['kind-stats-chart'];
  if (chart) {
    chart.options.library = {
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            return "Тип: " + tooltipItem[0].xLabel;
          },
          label: function (tooltipItem, data) {
            return "Отклонение от эталонного баланса квартир. Чем сильнее, тем хуже: " + tooltipItem.yLabel + "%";
          },
        }
      }
    };
    chart.setOptions(chart.options);
  }
});
