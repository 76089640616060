import flatpickr from "flatpickr"
import {Russian} from "flatpickr/dist/l10n/ru.js"

window.initDatepicker = function (opts = {}) {
  var defaultOpts = {"locale": Russian, dateFormat: "d.m.Y", allowInput: false}
  flatpickr('input.date', {...defaultOpts, ...opts});
};

$(document).ready(function () {
  initDatepicker();
});
