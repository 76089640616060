window.initNestedForm = function () {
  $("[data-form-prepend]").click(function (e) {
    var obj = $($(this).attr("data-form-prepend")),
        time = new Date().getTime();
    obj.find("input, select, textarea").each(function () {
      $(this).attr("name", function () {
        return $(this)
            .attr("name")
            .replace("new_record", time);
      });
    });
    obj.find("label.boolean").each(function () {
      $(this).attr("for", function () {
        return $(this)
            .attr("for")
            .replace("new_record", time);
      });
    });
    obj.find("input[type='checkbox']").each(function () {
      $(this).attr("id", function () {
        return $(this)
            .attr("id")
            .replace("new_record", time);
      });
    });
    obj.insertBefore($(this).parent('.additional_object'));
    return false;
  });
}

$(document).ready(function () {
  initNestedForm();
});
