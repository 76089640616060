import 'suggestions-jquery/dist/js/jquery.suggestions.min'
import 'suggestions-jquery/dist/css/suggestions.min.css'

window.initSuggestions = function (address) {
  address.suggestions({
    token: "1f0960f97451586ab73bb9e5e2d0a071a43dda34",
    type: "ADDRESS",
    /* Вызывается, когда пользователь выбирает одну из подсказок */
    onSelect: function (suggestion) {
      let data = suggestion.data,
          classes_arr = ['fias_id', 'okato', 'oktmo', 'area_type', 'area', 'settlement_type', 'settlement', 'city_type', 'city', 'city_district_type',
            'city_district', 'street_type', 'street', 'house_type', 'house', 'block_type', 'block', 'flat_type', 'flat'],
          $this = $(this);

      $.each(classes_arr, function (index, value) {
        $this.closest('.address-fields').find('.' + value).val(data[value]);
      });

      $this.closest('.address-fields').find('.kladr').val(data.kladr_id);
      $this.closest('.address-fields').find('.region').val(data.region_kladr_id.substr(0, 2));
      $this.closest('.address-fields').find('.postal_index').val(data.postal_code);
    }
  });
  // парсим данные по заранее заполненому инпуту адреса
  address.suggestions().fixData();
};

$(document).ready(function () {
  var $address = $(".address");

  if ($address.length > 0)
    initSuggestions($address);
});