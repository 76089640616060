// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import { createConsumer, getConfig } from "@rails/actioncable";
import { getCookie } from "../packs/get_cookie";

function getWebSocketURL() {
  const token = getCookie('developer.id'),
      url = getConfig("url");
  if (token) {
    return `${url}?token=${token}`
  } else {
    return url
  }
}

export default createConsumer(getWebSocketURL)
